@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.wrapper {
	font-family: 'Open Sans', sans;
	text-align: center;
  /* background-color: #444; */
}

#threesixty {
	margin: 0 auto;
}

#prev,
#next {
	padding: 0 30px;
	cursor: pointer;
}

.g-hub {
  color: #444;
  font-size: 0.9em;
}
.i-360{
width:100%;
height:100%
}
#cke_longDesc{
width:100%
}
#cke_chrome{
    width:100%
    }
    .cke_reset{
        width:100%
    }
